<template>
  <div class="section">
    <h5
      class="d-md-none"
      style="
        font-style: 400;
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
        text-align: center !important;
        color: #39454c;
        margin-bottom: 24px !important;
      ">
      {{ $t("legal-document.title") }}
    </h5>
    <div class="row d-flex justify-content-center" v-if="isCompanyFetched">
      <div
        v-for="(item, index) in companySettings.legal_documents"
        :key="index"
        class=" "
        :class="[
          { 'justify-content-start': index % 2 == 0 },
          { 'justify-content-end': index % 2 == 1 },
          'col-12',
          'col-md-6',
          'd-flex',
        ]"
        style="padding: 0 !important">
        <div class="file-card bg-white d-flex justify-content-start align-items-center">
          <div
            class="icon"
            @click.stop="
              downloadItem({
                url: item.document_url,
                label: item.document_name,
              })
            ">
            <DownloadIcon id="icon" />
          </div>
          <router-link
            :to="`legal-documents/${item.document_name}`"
            target="_blank"
            class="light-theme-title title roboto h-100">
            <span v-if="$te(`legal-document.files-${index + 1}`)">
              {{ $t(`legal-document.files-${index + 1}`) }}
            </span>
            <span v-else>{{ item.document_name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "../../svg/DownloadIcon.vue";
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: { DownloadIcon },
  data() {
    return {
      entity: localStorage.getItem("APP_IDENTITY"),
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters({
      companySettings: "companySettings",
      isCompanyFetched: "isCompanyFetched",
    }),
  },
  methods: {
    getFile() {},
    async downloadItem({ url, label }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-card {
  width: 100%;
  box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
  @media (max-width: 767px) {
    border-radius: 11.7199px;
    height: 88px !important;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    border-radius: 18px;
    height: 93.04px !important;
    max-width: 320px !important;
  }
  @media (min-width: 1200px) {
    border-radius: 18px;
    width: 336px !important;
    height: 104px !important;
  }
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #39454c;
  display: flex;
  align-items: center;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  @media (max-width: 375px) {
    width: 200px;
  }
  @media (max-width: 767px) and(min-width: 376px) {
    width: 80%;
  }
}
.file-card:hover {
  cursor: pointer;
}
.icon {
  margin-left: 20px;
}

.row {
  display: flex;

  @media (max-width: 767px) {
    gap: 32px;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    row-gap: 52px;
  }
  @media (min-width: 1200px) {
    row-gap: 52px;
    max-width: 712px;
    margin: 0 auto;
  }
}

#icon {
  @media (max-width: 767px) {
    width: 24px !important;
    height: 24px !important;
    margin-right: 8px !important;
  }
  @media (min-width: 768px) {
    width: 32px !important;
    height: 32px !important;
  }
}
@media (min-width: 768px) {
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #39454c;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 250px;
  }
}

.section {
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 160px;
  }
}
</style>
