var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4 6.66536C4 3.71985 6.38781 1.33203 9.33333 1.33203H20.4575C21.872 1.33203 23.2286 1.89393 24.2288 2.89413L26.4379 5.10327C27.4381 6.10346 28 7.46002 28 8.8745V25.332C28 28.2775 25.6122 30.6654 22.6667 30.6654H9.33333C6.38781 30.6654 4 28.2775 4 25.332V6.66536ZM25.3333 10.6654V25.332C25.3333 26.8048 24.1394 27.9987 22.6667 27.9987H9.33333C7.86057 27.9987 6.66667 26.8048 6.66667 25.332V6.66536C6.66667 5.19261 7.86057 3.9987 9.33333 3.9987H18.6667V6.66536C18.6667 8.8745 20.4575 10.6654 22.6667 10.6654H25.3333ZM25.1854 7.9987C25.0545 7.62216 24.8395 7.27613 24.5523 6.98889L22.3431 4.77975C22.0559 4.4925 21.7099 4.27755 21.3333 4.14662V6.66536C21.3333 7.40174 21.9303 7.9987 22.6667 7.9987H25.1854Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M16.5107 23.8988C16.668 23.8338 16.8153 23.7373 16.9431 23.6095L20.9431 19.6095C21.4638 19.0888 21.4638 18.2446 20.9431 17.7239C20.4224 17.2032 19.5782 17.2032 19.0575 17.7239L17.3337 19.4477V13.3333C17.3337 12.597 16.7367 12 16.0003 12C15.2639 12 14.667 12.597 14.667 13.3333V19.4477L12.9431 17.7239C12.4224 17.2032 11.5782 17.2032 11.0575 17.7239C10.5368 18.2446 10.5368 19.0888 11.0575 19.6095L15.0575 23.6095C15.4504 24.0023 16.0274 24.0988 16.5107 23.8988Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }