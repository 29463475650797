var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1395",
      "height": "646",
      "viewBox": "0 0 1395 646",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "293.391",
      "y": "231.102",
      "width": "137.609",
      "height": "115.793",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "286",
      "y": "217",
      "width": "118.828",
      "height": "63.4483",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "305.141",
      "y": "236.977",
      "width": "42.7931",
      "height": "5.87356",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "305.141",
      "y": "247.047",
      "width": "42.7931",
      "height": "5.87356",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M411.103 366.195L402.458 356.73L393.801 366.195V340.824H411.103V366.195Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M414.854 331.736C414.854 338.653 409.261 344.264 402.368 344.264C399.278 344.264 396.463 343.147 394.274 341.276C391.584 338.979 389.883 335.552 389.883 331.736C389.883 324.819 395.475 319.207 402.368 319.207C409.261 319.207 414.854 324.819 414.854 331.736Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "378.441",
      "y": "96.1445",
      "width": "17.6057",
      "height": "8.12573",
      "transform": "rotate(-51.051 378.441 96.1445)",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "351.852",
      "y": "79.7109",
      "width": "25.7315",
      "height": "10.8343",
      "transform": "rotate(-51.051 351.852 79.7109)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M366.277 117.66L407.142 67.1048",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "347.32",
      "y": "102.336",
      "width": "66.3601",
      "height": "44.6915",
      "transform": "rotate(-51.051 347.32 102.336)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "980.43",
      "y": "259.527",
      "width": "9.77235",
      "height": "4.51031",
      "transform": "rotate(54.2858 980.43 259.527)",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "993.133",
      "y": "247.707",
      "width": "14.2827",
      "height": "6.01375",
      "transform": "rotate(54.2858 993.133 247.707)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M970.699 249.859L991.762 279.156",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "981.688",
      "y": "241.961",
      "width": "36.8342",
      "height": "24.8067",
      "transform": "rotate(54.2858 981.688 241.961)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "1061.9",
      "y": "12.4141",
      "width": "113.103",
      "height": "95.1724",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "1056",
      "y": "1",
      "width": "97.3103",
      "height": "51.7931",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "1104.65",
      "y": "28.9648",
      "width": "35.1724",
      "height": "4.82759",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "1104.65",
      "y": "37.2422",
      "width": "35.1724",
      "height": "4.82759",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M1158.65 123.447L1151.54 115.667L1144.43 123.447V102.594H1158.65V123.447Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M1161.73 95.1256C1161.73 100.811 1157.13 105.423 1151.47 105.423C1148.93 105.423 1146.62 104.505 1144.82 102.967C1142.61 101.079 1141.21 98.2623 1141.21 95.1256C1141.21 89.4403 1145.8 84.8281 1151.47 84.8281C1157.13 84.8281 1161.73 89.4403 1161.73 95.1256Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 591 242)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 999 119)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 1037 318)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.137 122.711L454.712 130.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M454.715 122.711L462.139 130.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M528.137 210.711L520.712 218.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M520.715 210.711L528.139 218.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M365.137 386.711L357.712 394.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M357.715 386.711L365.139 394.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M517.137 287.711L509.712 295.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M509.715 287.711L517.139 295.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1152.14 317.711L1144.71 325.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1144.71 317.711L1152.14 325.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M824.137 114.711L816.712 122.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M816.715 114.711L824.139 122.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1329.02 55C1342.92 161.829 1326.76 380.578 1150.85 400.939C930.962 426.391 851.383 223.626 656.791 280.11C462.2 336.594 373.138 624.226 223.058 553.577C102.995 497.058 47.7909 500.797 35.1968 509.732",
      "stroke": "#ED6400",
      "stroke-width": "2.44142",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.88 4.88"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }