var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero position-relative overflow-hide"
  }, [_c('div', {
    staticClass: "d-md-none text-center mx-auto",
    staticStyle: {
      "max-width": "311px"
    }
  }, [_c('HeroMobile', {
    staticStyle: {
      "transform": "translateX(-280px)"
    }
  })], 1), _c('TabletHero', {
    staticClass: "d-none d-md-block d-xl-none hero-tablet"
  }), _c('DesktopHero', {
    staticClass: "d-none d-xl-block hero-desktop"
  }), _c('div', {
    staticClass: "hero-content position-absolute"
  }, [_c('h1', {
    staticClass: "text-center",
    staticStyle: {
      "color": "#373f41"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("legal-document.hero.header")) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }