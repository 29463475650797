<template>
  <div class="hero position-relative overflow-hide">
    <div class="d-md-none text-center mx-auto" style="max-width: 311px">
      <HeroMobile style="transform: translateX(-280px)" />
    </div>
    <TabletHero class="d-none d-md-block d-xl-none hero-tablet" />
    <DesktopHero class="d-none d-xl-block hero-desktop" />

    <div class="hero-content position-absolute">
      <h1 class="text-center" style="color: #373f41">
        {{ $t("legal-document.hero.header") }}
      </h1>
    </div>
  </div>
</template>
<script>
import DesktopHero from "../../svg/desktop/DesktopHero.vue";
import TabletHero from "../../svg/tablet/TabletHero.vue";
import HeroMobile from "../../svg/mobile/HeroMobile.vue";
export default {
  components: { DesktopHero, TabletHero, HeroMobile },
};
</script>

<style lang="scss" scoped>
.overflow-hide {
  @media (max-width: 767px) {
    overflow: hidden !important;
  }
}

.hero-tablet {
  // width: 150%;
  // margin-left: -28%;
  // background-color: red;
  @media (min-width: 768px) and (max-width: 991px) {
    transform: translate(-370px, 0px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    transform: translate(-260px, 0px);
  }
}
h1 {
  text-align: center;

  color: #373f41;
  @media (max-width: 767px) {
    margin-top: 20px;
    max-width: 350px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 50px;
  }
  @media (min-width: 1200px) {
    margin-top: 100px;
    // margin-left: 130px;
  }
}
@media (max-width: 767px) {
  .hero-content {
    /* top: ; */
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .hero-content {
    /* top: 19vh; */
  }
}

@media (min-width: 1200px) {
  .hero-content {
    /* top: 14vh; */
  }
}
.hero-desktop {
  margin-left: -220px;
}
</style>
