var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1135",
      "height": "700",
      "viewBox": "0 0 1135 700",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M337.773 88.7195H288.567C288.567 82.4125 292.749 77.0483 298.573 75.086C300.028 68.5946 306 63.7324 313.146 63.7324C320.282 63.7324 326.248 68.5843 327.709 75.0655C333.564 77.0124 337.773 82.392 337.773 88.7195Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M342 76.0337H317.782C317.782 72.9288 319.84 70.2879 322.706 69.3219C323.423 66.1261 326.362 63.7324 329.879 63.7324C333.391 63.7324 336.328 66.1211 337.047 69.3118C339.929 70.2703 342 72.9187 342 76.0337Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M594.585 246H541.415C541.415 239.185 545.934 233.389 552.226 231.268C553.8 224.254 560.252 219 567.974 219C575.685 219 582.132 224.243 583.71 231.246C590.037 233.35 594.585 239.163 594.585 246Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M599.152 232.292H572.983C572.983 228.937 575.207 226.084 578.304 225.04C579.078 221.587 582.254 219 586.055 219C589.85 219 593.023 221.581 593.8 225.029C596.914 226.065 599.152 228.926 599.152 232.292Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M408.541 77.5117L403.512 82.5408",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M403.517 77.5117L408.546 82.5408",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M491.541 216.512L486.512 221.541",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M486.517 216.512L491.546 221.541",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M103.537 2C73.883 86.6217 49.5197 267.556 189.295 314.319C364.015 372.774 463.616 221.284 612.411 300.673C761.207 380.061 784.395 629.604 918.749 597.813C1026.23 572.379 1070.55 584.896 1079.28 594.333",
      "stroke": "#07B53B",
      "stroke-width": "2.03206",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.06 4.06"
    }
  }), _c('rect', {
    attrs: {
      "x": "537.865",
      "y": "45.1738",
      "width": "47.1352",
      "height": "39.6625",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "535.704",
      "y": "40.7145",
      "width": "39.9579",
      "height": "20.9889",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.42906"
    }
  }), _c('rect', {
    attrs: {
      "x": "541.887",
      "y": "47.1855",
      "width": "14.6579",
      "height": "2.01187",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "541.887",
      "y": "50.6348",
      "width": "14.6579",
      "height": "2.01187",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M578.184 91.4481L575.223 88.2059L572.258 91.4481V82.7578H578.184V91.4481Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M579.469 79.6449C579.469 82.0142 577.553 83.9363 575.192 83.9363C574.133 83.9363 573.169 83.5536 572.419 82.9129C571.498 82.126 570.915 80.9521 570.915 79.6449C570.915 77.2756 572.831 75.3535 575.192 75.3535C577.553 75.3535 579.469 77.2756 579.469 79.6449Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "429.885",
      "y": "244.033",
      "width": "9.77235",
      "height": "4.51031",
      "transform": "rotate(54.2858 429.885 244.033)",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "442.588",
      "y": "232.213",
      "width": "14.2827",
      "height": "6.01375",
      "transform": "rotate(54.2858 442.588 232.213)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M420.154 234.365L441.217 263.662",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "431.142",
      "y": "226.467",
      "width": "36.8342",
      "height": "24.8067",
      "transform": "rotate(54.2858 431.142 226.467)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "231.885",
      "y": "219.795",
      "width": "80.1148",
      "height": "67.4136",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "227.766",
      "y": "211.766",
      "width": "68.8131",
      "height": "36.5718",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.53153"
    }
  }), _c('rect', {
    attrs: {
      "x": "238.726",
      "y": "223.215",
      "width": "24.9137",
      "height": "3.41953",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "238.726",
      "y": "229.078",
      "width": "24.9137",
      "height": "3.41953",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M300.416 298.441L295.383 292.93L290.343 298.441V283.67H300.416V298.441Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M302.602 278.38C302.602 282.407 299.346 285.674 295.333 285.674C293.534 285.674 291.895 285.023 290.62 283.935C289.055 282.597 288.064 280.602 288.064 278.38C288.064 274.353 291.32 271.086 295.333 271.086C299.346 271.086 302.602 274.353 302.602 278.38Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }