var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section mb-5"
  }, [_c('p', {
    staticClass: "body-paragraph-1 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("legal-document.text")) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }