var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1440",
      "height": "859",
      "viewBox": "0 0 1440 859",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 1309 166)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 177.004 421)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1196.14 245.711L1188.72 253.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1188.72 245.711L1196.14 253.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M276.141 491.711L268.716 499.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M268.719 491.711L276.143 499.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 261.004 120)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 1035 159)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "5",
      "transform": "matrix(-1 0 0 1 1073 358)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M498.141 162.711L490.716 170.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M490.719 162.711L498.143 170.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M337.141 278.711L329.716 286.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M329.719 278.711L337.143 286.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M401.141 426.711L393.716 434.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M393.719 426.711L401.143 434.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M553.141 327.711L545.716 335.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M545.719 327.711L553.143 335.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1188.14 357.711L1180.72 365.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1180.72 357.711L1188.14 365.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M837.141 122.711L829.716 130.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M829.719 122.711L837.143 130.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M241.141 184.711L233.716 192.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M233.719 184.711L241.143 192.136",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1411.35 2C1439.72 131.301 1443.18 400.809 1230 444.431C963.528 498.959 844.557 259.194 612.344 349.023C380.13 438.852 301.672 800.434 110.437 729.897C-42.5501 673.467 -109.733 683.912 -124.201 696.189",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "6 6"
    }
  }), _c('rect', {
    attrs: {
      "x": "207.004",
      "y": "364",
      "width": "164",
      "height": "138",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "198.004",
      "y": "347",
      "width": "142",
      "height": "76",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "221.004",
      "y": "371",
      "width": "51",
      "height": "7",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "221.004",
      "y": "383",
      "width": "51",
      "height": "7",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M347.292 524.998L336.989 513.718L326.672 524.998V494.762H347.292V524.998Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M351.764 483.931C351.764 492.175 345.099 498.863 336.884 498.863C333.201 498.863 329.846 497.531 327.237 495.302C324.032 492.564 322.004 488.48 322.004 483.931C322.004 475.688 328.669 469 336.884 469C345.099 469 351.764 475.688 351.764 483.931Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "1199",
      "y": "141",
      "width": "164",
      "height": "138",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "1190",
      "y": "124",
      "width": "142",
      "height": "76",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "1261",
      "y": "165",
      "width": "51",
      "height": "7",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "1261",
      "y": "177",
      "width": "51",
      "height": "7",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M1339.29 301.998L1328.99 290.718L1318.67 301.998V271.762H1339.29V301.998Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M1343.76 260.931C1343.76 269.175 1337.1 275.863 1328.88 275.863C1325.2 275.863 1321.85 274.531 1319.24 272.302C1316.03 269.564 1314 265.48 1314 260.931C1314 252.688 1320.67 246 1328.88 246C1337.1 246 1343.76 252.688 1343.76 260.931Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "376.445",
      "y": "220.145",
      "width": "17.6057",
      "height": "8.12573",
      "transform": "rotate(-51.051 376.445 220.145)",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "349.855",
      "y": "203.711",
      "width": "25.7315",
      "height": "10.8343",
      "transform": "rotate(-51.051 349.855 203.711)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M364.281 241.66L405.146 191.105",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "345.324",
      "y": "226.336",
      "width": "66.3601",
      "height": "44.6915",
      "transform": "rotate(-51.051 345.324 226.336)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "991.438",
      "y": "327.527",
      "width": "9.77235",
      "height": "4.51031",
      "transform": "rotate(54.2858 991.438 327.527)",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "1004.14",
      "y": "315.707",
      "width": "14.2827",
      "height": "6.01375",
      "transform": "rotate(54.2858 1004.14 315.707)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M981.707 317.859L1002.77 347.156",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "992.695",
      "y": "309.961",
      "width": "36.8342",
      "height": "24.8067",
      "transform": "rotate(54.2858 992.695 309.961)",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }