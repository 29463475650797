<script>
import Hero from "./components/sections/Hero.vue";
import SomeInfo from "./components/sections/SomeInfo.vue";
import Files from "./components/sections/Files.vue";
import GoToFormButton from "./components/sections/GoToFormButton.vue";
export default {
	components: { Hero, SomeInfo, Files, GoToFormButton },
	metaInfo: {
    title: 'NOZAX: Regulation & License ',
    meta: [
      { name: 'description', content: 'Regulated and licensed in Montenegro. Find out more about us in our documents,  and start building your portfolio with NOZAX.' },
    ]
  }
};
</script>

<template>
	<div>
		<Hero />
		<SomeInfo />
		<Files />
		<GoToFormButton />
	</div>
</template>

<style scoped></style>
