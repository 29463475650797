var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section"
  }, [_c('h5', {
    staticClass: "d-md-none",
    staticStyle: {
      "font-style": "400",
      "font-weight": "500",
      "font-size": "28px",
      "line-height": "120%",
      "text-align": "center !important",
      "color": "#39454c",
      "margin-bottom": "24px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("legal-document.title")) + " ")]), _vm.isCompanyFetched ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, _vm._l(_vm.companySettings.legal_documents, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: " ",
      class: [{
        'justify-content-start': index % 2 == 0
      }, {
        'justify-content-end': index % 2 == 1
      }, 'col-12', 'col-md-6', 'd-flex'],
      staticStyle: {
        "padding": "0 !important"
      }
    }, [_c('div', {
      staticClass: "file-card bg-white d-flex justify-content-start align-items-center"
    }, [_c('div', {
      staticClass: "icon",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.downloadItem({
            url: item.document_url,
            label: item.document_name
          });
        }
      }
    }, [_c('DownloadIcon', {
      attrs: {
        "id": "icon"
      }
    })], 1), _c('router-link', {
      staticClass: "light-theme-title title roboto h-100",
      attrs: {
        "to": ("legal-documents/" + (item.document_name)),
        "target": "_blank"
      }
    }, [_vm.$te(("legal-document.files-" + (index + 1))) ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(("legal-document.files-" + (index + 1)))) + " ")]) : _c('span', [_vm._v(_vm._s(item.document_name))])])], 1)]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }