var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section text-center button"
  }, [_c('b-button', {
    staticClass: "round-btn-19-20",
    attrs: {
      "variant": "primary"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/complaint-form"
    }
  }, [_c('p', {
    staticClass: "button-large text-white-button"
  }, [_vm._v(" " + _vm._s(_vm.$t("legal-document.button")) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }