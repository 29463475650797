<template>
  <div class="section text-center button">
    <b-button variant="primary" class="round-btn-19-20">
      <router-link class="link" to="/complaint-form">
        <p class="button-large text-white-button">
          {{ $t("legal-document.button") }}
        </p>
      </router-link>
    </b-button>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.round-btn-19-20 {
  @media (max-width: 767px) {
    height: 48px !important;
    padding: 0 !important;
    width: 100%;
    max-width: 304px !important;
  }
  @media (min-width: 768px) {
    height: 56px !important;
    padding: 0 !important;
    width: 100%;
    max-width: 355px !important;
  }
}
.button-large {
  @media (max-width: 767px) {
    //font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    text-align: center;
    letter-spacing: 0.02em;
  }
}
.button {
  @media (max-width: 767px) {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 157px;
  }
  @media (min-width: 1200px) {
    margin-top: 80px;
    margin-bottom: 64px;
  }
}
</style>
